<template>
  <div class="d-flex-column align-center main">
    <el-card>
      <div class="mw-400 main">
        <h3>Thanks!</h3>
        <p>We've received your information and will be in touch shortly.</p>
      </div>
    </el-card>
    <div class="align-text-center main mw-400">
      <el-button type="primary" class="full-width" @click="goToHome">Back To Home</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact Received',
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  font-family: $fontMedium, Arial;
}
p {
  text-align: center;
}
</style>
